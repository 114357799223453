.pointer {
  cursor: pointer;
}

.imgbutton {
  background: inherit;
  border: none;
  margin: 0;
  padding: 0;
}

input[type="button"][disabled], button[disabled] {
  opacity: 0.5;
}

.sort-disable {
  opacity: 0.5;
}

/*Diolog Boxes Styles Here*/
.modal {
  background: rgba(0,0,0,0.1);
}

.actionmodels {
  max-width: 600px;
  position: fixed;
  top: 0;
  left: 32%;
  z-index: 1981;
  display: none;
  width: 100%;
  height: inherit !important;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

  .actionmodels .modal-content {
    margin-top: 0;
    position: relative;
  }

  .actionmodels .close, .actionmodels .close:hover {
    color: #6f6f6f;
    position: absolute;
    right: 5px;
    top: 3px;
    padding: 0;
    opacity: inherit;
  }

.alertPopover p, .successMessage p {
  font-size: 13px;
  color: #F00;
}

.alertPopover .close:focus, .successMessage .close:focus {
  outline: none;
  border: none;
}

.successMessage p {
  color: #093;
}

.session-success {
  border-top: 3px solid #093;
  color: #093;
}
/*Z-index clasess*/
.z-index-2 {
  z-index: 1982;
}

.z-index-3 {
  z-index: 1984;
}

/*Diolog Boxes Styles Here end*/

/*===== download model start =====*/
.download-pop .modal-header {
  padding: 6px 20px;
  background: #4b6981;
  color: #fff;
  border-radius: 0;
}

.download-pop .modal-title {
  font-size: 16px;
  color: #ffffff;
}

.download-pop .close {
  color: #ffffff;
  opacity: 1;
  font-size: 20px;
  padding: 18px 14px;
}
/*===== download model end =====*/

.alertPopover p, .successMessage p {
  font-size: 13px;
  color: #F00;
}

.alertPopover .close:focus, .successMessage .close:focus {
  outline: none;
  border: none;
}

.successMessage p {
  color: #093;
}

.session-success {
  border-top: 3px solid #093;
  color: #093;
}


.fa-ban:before {
  content: "\f05e";
}

.fa-check-circle:before {
  content: "\f058";
}

/* .mandatory:after {
  content: '*';
  position: absolute;
  right: -10px;
  top: -6px;
  font-size: 14px;
  color: #F00;
} */

.iframe-loader {
  background: url(../images/spinner.gif) center center no-repeat;
}

/*POPOVERLY STYLES HERE*/

.popopslide-main {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.4s ease;
  -webkit-transform: translate(100%);
  transform: translate(100%);
  background: rgba(0,0,0,0.1);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  z-index: 1980;
}

  .popopslide-main.open-canvas {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

.popopslide-inner {
  width: 90%;
  height: 100%;
  padding: 0 30px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #ccc;
}

.popopslide-headrow {
  width: 100%;
  display: inline-block;
  padding: 0 0 10px 0;
}

.popopslide-heading {
  float: left;
  padding: 6px 0 6px 0;
  font-size: 20px;
  text-align: left;
  color: #495058;
}

  .popopslide-heading h5 {
    margin-bottom: 0px;
  }

.popover-hdr .fa-save, .popover-hdr .fa-times {
  font-size: 25px;
  color: #4c6a82;
}

/*POPOVERLY WIDTHS*/
.popover-30 {
  width: 30%;
}

.popover-40 {
  width: 40%;
}

.popover-50 {
  width: 50%;
}

.popover-60 {
  width: 60%;
}

.popover-70 {
  width: 70%;
}

.popover-80 {
  width: 80%;
}

.popover-90 {
  width: 90%;
}

.popover-95 {
  width: 95%;
}

.popover-97 {
  width: 97%;
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.image-button {
  border: none;
  background: inherit;
}

.edit-inline {
  display: inline-block;
}

.edit-pd08 {
  padding: 0 8px;
}

.help-16 {
  width: 16px;
  height: 16px;
}

/*Captcha Styles*/
#captcahCanvas {
  width: 316px;
  padding: 3px 0;
  border-radius: 22px 22px 0px 0px;
  color: #264c71;
  font-weight: bold;
  height: 70px;
}

.captcha-actions {
  margin: -9px 0 0 0;
}

  .captcha-actions input {
    width: 291px;
    border-radius: 0px 0px 0px 16px;
    border: 1px solid #c5c5c5;
    border-top:0;
    padding-left:8px;
  }
    .captcha-actions input:focus {
      border: 1px solid #c5c5c5;
      outline: none;
    }
    .captcha-actions a {
    background: #ccc;
    padding: 3px 6px;
    border-radius: 0px 6px 6px 0px;
    color: #264c71;
    font-weight: bold;
  }
/*Captcha Styles*/



/* Alertify Popups Styles Start*/
.modelHeader {
  background: #1a2024;
  padding: 8px 15px;
  color: #fff;
  font-size: 18px;
}

.btn-default {
  padding: 7px 15px;
  background: #2f54b7;
  color: #fff;
  border-radius: 4px;
}
  .btn-default:disabled:hover {
    background: #2f54b7;
    color: #fff;
  }
  /* Alertify Popups Styles End*/
  .contact-dtls a {
    padding: 0;
    font-size: 12px;
    text-align: left;
    display: inline-block;
    width: auto;
    float: none;
  }

a.disabled {
  color: gray;
  cursor: not-allowed;
  text-decoration: underline;
}

.tabsMain .nav-tabs .nav-link.completed{
	border: none;
	background: #17183b;
}

.tabsMain .nav-tabs .nav-item .nav-link.completed:after{
	border-bottom: 3px solid #17183b;
}

.tabsMain .nav-tabs .nav-item:last-child .nav-link.completed:after{
	border: none;
}

.reset-password-form-content
{
  width: 50%;
  margin: 13% auto;
}

.reset-password-content
{
  width: 500px;
  margin: 13% auto;
  border: 1px solid #aebecf;
  padding: 20px;
}

.owl-nav {
  position: initial !important;
}

.owl-carousel .owl-nav .owl-prev {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 93%;
  top: -20px;
  font-size: inherit;
  background: url(../images/previw.png) no-repeat left top !important;

}
.owl-carousel .owl-nav .owl-next {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  top: -20px;
  font-size: inherit;
  background: url(../images/next.png) repeat left top !important;
}
.owl-carousel .owl-nav .owl-prev span,.owl-carousel .owl-nav .owl-next span{font-size: 0;}


.pushaheadfield
{
  position: absolute;
  right: 6px;
  top: 5px;
}

.pushaheadfield i.fa-long-arrow-alt-down
{
  font-size: 16px;
  color: #434343;
}

.accordion .card {
  margin-bottom: 10px;
  border: 0;
}

.search-criteria .card .card-header[aria-expanded="true"] {
  background-color: #005baa;
  color: #fff;
}

.search-criteria .card .card-header {
  padding: 10px 10px;
  background-color: #fff;
  color: #005baa;          
  /* //0a045b; */
  border-radius: 10px;
  cursor: pointer;
  border-color: #005baa;
  /* #0a045b; */
  border: 1px solid;
  margin-bottom: 0px;
}

.search-criteria .card .card-header[aria-expanded="true"] .fa-minus-square:before, .search-criteria .card .card-header[aria-expanded="true"] .fa-plus-square:before {
  color: #fff;
}

.search-criteria .card-header .fa-minus-square:before, .search-criteria .card-header .fa-plus-square:before {
  color: #005baa;
   /* #0a045b; */
}

.search-criteria .card .card-header[aria-expanded="true"] p {
  line-height: 16px;
  color: #fff;
}

.search-criteria .card-header p {
  line-height: 16px;
  color: #005baa;        
    /* #0a045b; */
}

.search-criteria .tp-card > .tp-card-body {
  border: 1px solid #005baa;
  /* #0a045b; */
  border-radius: 10px;
}

.search-criteria-2 .card[aria-expanded="true"] {
  background-color: #fff;
  color: #007bff;
  border: 0;
  border-bottom: 1px solid;
}

.search-criteria-2 .card .card-header {
  padding: 10px 10px;
  background-color: #fff;
  color: #6b686c;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid;
  margin-bottom: 0px;
}

.search-criteria-2 .card[aria-expanded="true"] .fa-minus-square:before, .search-criteria-2 .card[aria-expanded="true"] .fa-plus-square:before {
  color: #007bff;
}

.search-criteria-2 .card-header .fa-minus-square:before, .search-criteria-2 .card-header .fa-plus-square:before {
  color: #6b686c;
}

.search-criteria-2 .card[aria-expanded="true"] p {
  color: #007bff;
}

.search-criteria-2 .card-header p {
  color: #6b686c;
}

.search-criteria-2 .card[aria-expanded="true"] {
  border: 1px solid #007bff !important;
  margin-bottom: 0px;
  border-top: 0px;
  border-radius: 10px !important;
}

.search-criteria-2 .card[aria-expanded="true"] .card-header{
  border: 0;
  border-bottom: 1px solid #007bff;
}

.search-criteria .card-header .fa-minus-square:before, 
.search-criteria-2 .card .card-header .fa-minus-square:before,
.search-criteria .card-header[aria-expanded="true"] .fa-plus-square:before,
.search-criteria-2 .card[aria-expanded="true"] .fa-plus-square:before {
  display: none;
}

.search-criteria .card-header[aria-expanded="true"] .fa-minus-square:before, 
.search-criteria-2 .card[aria-expanded="true"] .fa-minus-square:before{
  display: block;
}
.dropdown-menu-ovrd {
  min-width: 8.4rem !important;
  margin: -0.7rem 0 0 !important;
}

.subscribed
{
  text-decoration: underline;
  font-weight: 600;
}
i.fa-bookmark, .newsMore
{
  cursor: pointer;
}
.m-lft{
margin-left:4px;
}

tabset .nav-tabs .nav-link{
  background: #e8e7ff !important;
  color: #343434 !important;
}

tabset .nav-tabs .nav-link.active{
  background: #ff3b00 !important;
  color: white !important;
}

tabset .nav-tabs .nav-link:hover{
  background: #211981 !important;
  color: white !important;
}
.sortLabel
{
  padding: 6px 0;
}

accordion-group[panelClass="parentAccordion"].panel .card
{
  margin-bottom: 10px;
  border: 0;
}

accordion-group[panelClass="parentAccordion"].panel .card-header
{
  padding: 10px 10px;
  background-color: #fff;
  color: #005baa;
  /* #0a045b; */
  border-radius: 10px;
  cursor: pointer;
  border-color: #005baa;
  /* #0a045b; */
  border: 1px solid;
  margin-bottom: 0px;
  padding-bottom: 0.25rem!important;
  padding-top: 0.25rem!important;
}
accordion-group[panelClass="parentAccordion"].panel .card-header p{
  color: #005baa;
  /* #0a045b; */
}
accordion-group[panelClass="parentAccordion"].panel-open .card-header{
  background-color: #005baa;
  /* #0a045b; */
  color: #fff;
}
accordion-group[panelClass="parentAccordion"].panel-open .card-header p{
  color: #fff;
}

accordion-group[panelClass="parentAccordion"].panel .card-header h2
{
  padding: 10px 0;
  line-height: 15px;
  font-size: 16px;
}

accordion-group[panelClass="parentAccordion"].panel .panel-body
{
  border: 1px solid #005baa;
  /* #0a045b; */
  border-radius: 10px;
}

accordion-group[panelClass="childAccordion"].panel .card-header
{
  padding: 10px 10px;
  background-color: #fff;
  color: #6b686c;
  border-radius: 10px;
  cursor: pointer;
  border-color: #6b686c;
  border: 1px solid;
  margin-bottom: 0px;
  padding-bottom: 0.25rem!important;
  padding-top: 0.25rem!important;
}
accordion-group[panelClass="childAccordion"].panel .card-header p{
  color: #6b686c;
}
accordion-group[panelClass="childAccordion"].panel-open .card-header{
  color: #007bff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
accordion-group[panelClass="childAccordion"].panel-open .card-header p{
  color: #007bff;
}

accordion-group[panelClass="childAccordion"].panel .panel-body
{
  border: 1px solid #007bff;
  border-radius: 10px;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.block-ui-wrapper {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3) !important;
  z-index: 30000;
  cursor: wait;
}
