/* Large Devices, Wide Screens */
@media (min-width: 1919px) {
    .navbar-nav .nav-item:last-child a {
        border: none;
    }

    .navbar-nav .nav-link {
        line-height: 42px;
        font-size: 16px;
    }

    .useraccount {
        text-align: right;
        padding-right: 20px;
    }

    .useraccount .btn {
        font-size: 16px;
    }

}

/* Large Devices, Wide Screens */
@media (min-width: 1367px) and (max-width: 1918px) {
  .error-bg {
    height: 368px !important;
  }

    .error-bg h1 {
      padding: 90px 0 !important;
    }
    .navbar-nav .nav-link {
        line-height: 21px;
        font-size: 16px;
    }

    .useraccount {
        padding: 14px 17px 14px 0;
        text-align: right;
    }

    .useraccount .btn {
        font-size: 15px;
    }

}

@media (min-width: 1200px) and (max-width: 1366px) {
  .error-bg {
      height:446px !important;
  }
    .error-bg h1 {
        padding:60px 0 !important;
    }
    .navbar-nav .nav-link {
      line-height: 21px;
      font-size: 14px;
    }

    .useraccount {
        padding: 14px 17px 14px 0;
        text-align: right;
    }

}

/* Medium Devices, Desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  
    .pageContent {
        width: 96%;
    }

    .navbar-nav .nav-link {
        font-size: 13px;
        line-height: 17px;
    }

    .navbar {
        padding-right: 6px;
    }

    .useraccount {
        padding: 10px 0;
    }

    .useraccount .btn {
        font-size: 11px;
    }

    .navbar-nav .nav-item:first-child a {
        line-height: 34px;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: 93% !important;
    }

}

/* Small Devices, Tablets */
@media (min-width: 768px) and (max-width: 991px) {

    .title-md {
        font-size: 16px;
    }
  .navbar-nav .nav-item {
    height: auto;
    padding: 5px 10px;
  }
    .resourceCard {
        padding: 6px 14px;
    }

    .pageContent {
        width: 100%;
    }

    .pageContent {
        width: 96%;
        padding: 0 10px;
    }

    .promoCards {
        padding: 20px 8px;
    }

    .contact-dtls span {
        width: 16px;
    }

    .contact-dtls small,
    .contact-dtls a {
        width: calc(100% - 16px);
    }

    .navbar {
        width: 100%;
    }

    .useraccount {
        width: 100%;
        padding: 0;
    }

    #logo {
        display: none;
    }

    #logo-mbl {
        display: block;
        padding: 5px 0 8px 0;
    }

    #logo-mbl img {
        height: 25px;
    }

    .useraccount {
        display: none;
    }

    .mbl-useraccount {
        display: block;
        float: left;
        text-align: right;
    }

    .mbl-useraccount button,
    .mbl-useraccount button:focus {
        color: #000;
        padding: 0px 0 3px 0;
    }

    .topof-Header a {
        width: 50%;
    }

    .topof-Header a img {
        width: 100%;
    }

    .navbar {
        padding: 0 14px;
    }

    .navbar-toggler {
        position: absolute;
        right: 6px;
        top: 7px;
        width: 22px;
        height: 24px;
        padding: 0;
        color: #fff;
    }

    .nav-right {
        padding: 3px 0 5px 0;
        float: none;
        text-align: center;
    }

    .nav-right li {
        padding: 0;
        float: none;
        display: inline;
    }

    .registaerForm-fields {
        width: 96%;
    }

    .topof-Header ul {
        display: none;
    }

    .promoCards p {
        font-size: 12px;
    }

    .buttonsRow {
        text-align: center;
    }

    .buttonsRow .btn {
        padding: 5px 6px !important;
    }

    .tabsMain .nav-tabs .nav-item {
        padding: 0 45px;
    }

    .tabsMain .nav-tabs .nav-item .nav-link:after {
        width: 93px;
    }

    .tabsMain .nav-tabs {
        width: 370px;
    }

    .mbl-100 {
        padding-top: 10px;
        text-align: center !important;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: 91% !important;
    }


}

/* Extra Small Devices, Phones */
@media (min-width: 576px) and (max-width: 767px) {
    .title-md {
        font-size: 16px;
    }
  .navbar-nav .nav-item {
    height: auto;
    padding: 5px 10px;
  }
    .title-lg {
        font-size: 18px;
    }

    .navbar {
        width: 100%;
    }

    .useraccount {
        width: 100%;
        padding: 0;
    }

    #logo {
        display: none;
    }

    #logo-mbl {
        display: block;
        padding: 5px 0 8px 0;
    }

    #logo-mbl img {
        height: 24px;
    }

    .useraccount {
        display: none;
    }

    .registaerForm-fields {
        width: 100%;
    }

    .navbar-nav .nav-item:first-child a {
        line-height: 16px;
    }

    .mbl-useraccount {
        display: block;
        float: left;
        text-align: right;
    }

    .mbl-useraccount button,
    .mbl-useraccount button:focus {
        color: #000;
        padding: 0px 0 3px 0;
    }

    .topof-Header a {
        width: 50%;
    }

    .topof-Header a img {
        width: 100%;
    }

    .navbar {
        padding: 0 14px;
    }

    .navbar-toggler {
        position: absolute;
        right: 6px;
        top: 7px;
        width: 22px;
        height: 24px;
        padding: 0;
        color: #fff;
    }

    .nav-right {
        padding: 3px 0 5px 0;
        float: none;
        text-align: center;
    }

    .nav-right li {
        padding: 0;
        float: none;
        display: inline;
    }

    .topof-Header ul {
        display: none;
    }

    .registaerForm-fields {
        width: 96%;
    }

    .newsCard figure {
        position: inherit;
        left: 0;
        top: 0;
    }

    .buttonsRow {
        text-align: center;
    }

    .buttonsRow .btn {
        padding: 5px 6px !important;
    }

    .defaultTabs .nav-tabs .nav-item .nav-link {
        font-size: 16px;
    }

    .tabsMain .nav-tabs .nav-item {
        padding: 0 45px;
    }

    .tabsMain .nav-tabs .nav-item .nav-link:after {
        width: 93px;
    }

    .tabsMain .nav-tabs {
        width: 370px;
    }

    .mbl-100 {
        padding-top: 10px;
        text-align: center !important;
    }

    footer {
        padding: 30px 20px 20px 20px;
    }

    .footerLinks a {
        padding: 8px 16px;
    }

    .bookmarkcnt-tbl .table_header:nth-child(-n+4),
    .bookmarkcnt-tbl .table_small:nth-child(-n+4) {
        width: 15%;
    }

    .bookmarkcnt-tbl .table_header:nth-child(2),
    .bookmarkcnt-tbl .table_small:nth-child(2) {
        width: 66%;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: 86% !important;
    }

    #autoModal .modal-body {
        height: 270px;
        overflow: auto;
    }

}

@media (max-width: 575px) {
  .pageContent {
    width: 100%;
    padding: 0 14px;
  }
  .navbar-nav .nav-item {
      height:auto;
      padding:5px 10px;
  }
  .title-lg {
    font-size: 18px;
  }

    .navbar {
        width: 100%;
    }

    .navbar-nav .nav-item:first-child a {
        line-height: 16px;
    }

    .useraccount {
        width: 100%;
        padding: 0;
    }

    #logo {
        display: none;
    }

    #logo-mbl {
        display: block;
        padding: 5px 0 8px 0;
    }

    #logo-mbl img {
        height: 24px;
    }

    .useraccount {
        display: none;
    }

    .registaerForm-fields {
        width: 100%;
    }

    .mbl-useraccount {
        display: block;
        float: left;
        text-align: right;
    }

    .mbl-useraccount button,
    .mbl-useraccount button:focus {
        color: #000;
        padding: 0px 0 3px 0;
    }

    .topof-Header a {
        width: 50%;
    }

    .topof-Header a img {
        width: 100%;
    }

    .navbar {
        padding: 0 14px;
    }

    .navbar-toggler {
        position: absolute;
        right: 6px;
        top: 7px;
        width: 22px;
        height: 24px;
        padding: 0;
        color: #fff;
    }

    .nav-right {
        padding: 3px 0 5px 0;
        float: none;
        text-align: center;
    }

    .nav-right li {
        padding: 0;
        float: none;
        display: inline;
    }

    .topof-Header ul {
        display: none;
    }

    .topof-Header ul {
        display: none;
    }

    .newsCard figure {
        position: inherit;
        left: 0;
        top: 0;
    }

    .defaultTabs .nav-tabs .nav-item .nav-link {
        font-size: 12px;
    }

    .buttonsRow {
        text-align: center;
    }

    .buttonsRow .btn {
        padding: 5px 6px !important;
    }

    .tabsMain .nav-tabs .nav-item {
        padding: 0 24px;
    }

    .tabsMain .nav-tabs .nav-item .nav-link:after {
        width: 50px;
    }

    .tabsMain .nav-tabs {
        width: 244px;
    }

    .newsCard figure img {
        width: 100%;
    }

    .mbl-100 {
        text-align: center !important;
    }

    .mbl-100 .btn {
        width: 100%;
        margin-top: 10px;
    }

    .footerLinks:after,
    .quicklinks li a {
        border: none;
    }

    footer {
        padding: 30px 20px 20px 20px;
    }

    .footerLinks a {
        padding: 8px 12px;
        font-size: 13px;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: 86% !important;
    }

}

@media (max-width: 400px) {
    .pageContent {
        width: 100%;
        padding: 0 14px;
    }
  .navbar-nav .nav-item {
    height: auto;
    padding: 5px 10px;
  }
    .title-lg {
        font-size: 16px;
    }

    .modal-title {
        font-size: 14px;
    }

    .navbar {
        width: 100%;
    }

    .navbar-nav .nav-item:first-child a {
        line-height: 16px;
    }

    .useraccount {
        width: 100%;
        padding: 0;
    }

    #logo {
        display: none;
    }

    #logo-mbl {
        display: block;
        padding: 5px 0 8px 0;
    }

    #logo-mbl img {
        height: 24px;
    }

    .useraccount {
        display: none;
    }

    .registaerForm-fields {
        width: 100%;
    }

    .mbl-useraccount {
        display: block;
        float: left;
        text-align: right;
    }

    .mbl-useraccount button,
    .mbl-useraccount button:focus {
        color: #000;
        padding: 0px 0 3px 0;
    }

    .topof-Header a {
        width: 50%;
    }

    .topof-Header a img {
        width: 100%;
    }

    .navbar {
        padding: 0 14px;
    }

    .navbar-toggler {
        position: absolute;
        right: 6px;
        top: 7px;
        width: 22px;
        height: 24px;
        padding: 0;
        color: #fff;
    }

    .nav-right {
        padding: 3px 0 5px 0;
        float: none;
        text-align: center;
    }

    .nav-right li {
        padding: 0;
        float: none;
        display: inline;
    }

    .topof-Header ul {
        display: none;
    }

    .newsCard figure {
        position: inherit;
        left: 0;
        top: 0;
    }

    .defaultTabs .nav-tabs .nav-item .nav-link {
        font-size: 11px;
        padding: 5px 4px;
    }

    .buttonsRow .btn {
        width: 100%;
        margin: 10px 0 0 0;
        padding: 5px 10px !important;
    }

    .faqlistRow {
        padding-bottom: 20px
    }

    .tabsMain .nav-tabs .nav-item {
        padding: 0 24px;
    }

    .tabsMain .nav-tabs .nav-item .nav-link:after {
        width: 50px;
    }

    .tabsMain .nav-tabs {
        width: 244px;
    }

    footer {
        padding: 30px 15px 20px 15px;
    }

    .footerLinks:after,
    .quicklinks li a {
        border: none;
    }

    .footerLinks a::after {
        display: none;
    }

    .footerLinks a {
        display: block;
    }

    .fs-16 {
        font-size: 15px;
    }

    .fs-14 {
        font-size: 13px;
    }

    .mbl-100 .btn {
        width: 100%;
        margin-top: 10px;
    }

    .newsCard figure img {
        width: 100%;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: 86% !important;
    }

    .msl-contact-dtl a {
        display: flex;
        word-break: break-all;
    }

    .msl-contact-dtl a i {
        padding-right: 5px;
        margin-top: 4px !important;
    }

}
