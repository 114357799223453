@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
body, pre {
	font-family: 'robotoregular' !important;
}

a{text-decoration: none!important; color:#26a1c7;}
.text-underline{
	text-decoration: underline !important;
}
a:hover{
	text-decoration:none;
	color: #ff6a00 !important;
}
li{list-style-type: none;}
ul{padding-left: 0px; margin-bottom: 0;}

p{margin-bottom: 0px; color: #434343;}
.fs-11{
	font-size: 11px;
}
.fs-12{
	font-size: 12px;
}
.fs-12a{
	font-size: 12px;
    color: #ff0000;
}
.fs-13{
	font-size: 13px;
}
.fs-14{
	font-size: 14px;
}
.fs-16{
	font-size: 16px;
}
.fs-18{
	font-size: 18px;
}
.fs-20{
	font-size: 20px;
}
.fs-22{
	font-size: 22px!important;
}
.fs-30{
	font-size: 30px;
}
/* z INDEX */
.zindex-1{
	z-index: 900;
}
.zindex-2{
	z-index: 901;
}
.zindex-3{
	z-index: 902;
}
.clearfix {clear: both;}
.self-hcp-backdrop{
    background: rgba(0,0,0,0.9) !important;
}
/* Opacity clasess */
.opacity-1{
	opacity: 0.8;
}
.opacity-2{
	opacity: 0.6;
}
.opacity-3{
	opacity: 0.4;
}
.opacity-4{
	opacity: 0.2;
}
.bodyBg{
	background: #f7f7f7;
}
.title-sm,.title-md,.title-lg{
	font-size: 14px;
	color: #3083DC;
}
.title-md{
	font-size: 18px;
}
.fieldLabel{
	font-size: 13px;
	margin-bottom: 0px;
}
.mandatory{
	position: relative;
}
.mandatory:after {
    content: '*';
    position: absolute;
    left: -8px;
    top: -3px;
    font-size: 12px;
    color: #ff000c;
}
.dateField{
	position: relative;
}
.dateField span{
	background: #d9d9d9;
    position: absolute;
    right: 0;
    top: 0;
    padding: 4px 10px;
}
.title-lg{
	font-size: 24px;
	font-weight: 400;
}
.btn{
	padding: 6px 10px;
	font-size: 13px;
}

.form-group{
	margin-bottom: 10px;
}
.form-control{
	font-size: 13px;
	height: 32px;
}
.custom-control{
	padding-left: 1.4rem;
}
.custom-radio{
  padding-left: 0 !important;
}
.text-secondary{
	color:#434343;
}
.bg-secondary {
    background-color: #efefef!important;
}
.title-color-2{
	color: #17183B;
}
.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:hover {
    color: #fff !important;
    background-color: #0648ad;
    border-color: #0648ad;
}
.btn-outline-primary{
	border-color: #007bff;
}
.btn-outline-primary:hover{
	background-color: #0648ad;
	border-color: #0648ad;
}
.btn-primary:disabled:hover {
  background-color: #007bff;
  border-color: #007bff;
}
/* Radio Button and Checkbox Styles Here */
/* checkbox styles */
.custom-control-label::before {
  position: absolute;
  top: 4px;
  left: -24px;
  display: block;
  width: 16px;
  height: 16px;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
  background-color: #fff;
  border: 1px solid #879cb4;
}
 .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #fff;
 }
 .custom-control-input:checked~.custom-control-label::before {
    color: #bd7e7e;
    background-color: #e27474;
	    border-color: #879cb4;
 }
 .custom-checkbox .custom-control-label::before {
    border-radius: inherit;
 }
 .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
 content:"";
    left: 4px;
    top: 6px;
 font-size:0;
 background-image:inherit;
 background:url(../images/checkArrow.png) no-repeat center top;
 }
 .custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #1aa8d6;
 }
 .custom-control-label::after{
 width:16px;
 height:16px;
 }
 .custom-checkbox label ,.custom-control-label{
    font-size: 13px;
    line-height: 22px;
    color: #666666;}
  .custom-control-label{
    line-height: 24px;
    padding-left: 26px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .custom-control-label::before,.custom-control-label::after{left: 4px;}
.chkbox-hdr{    padding: 5px 0 5px 14px;
    background: #d8e8ee;
    color: #4c6a82;
    font-size: 14px;}
 /* checkbox end */
 
/*Radio Button*/
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #1aa8d6;
}
/* G */

/* Modal Styles Here */
.modal{
    padding-right: 0;
}
.modal-header{
	padding: 4px 14px;
	background: #0c0c1e;
	color: #fff;
}
.modal-header .close{
	opacity: 1;
	color: #fff;
}
.modal-content{border: none;}
#autoModal .modal-body{
	padding: 20px 40px;
}
.modalbody-ht{
	height: calc(100vh - 140px);
	overflow: auto;
}
.main-body{overflow: hidden;}
header{
	width: 100%;
	float: left;
}
#logo{
	display: block;
	float: left;
	padding: 4px 0;
}
#logo-mbl,.mbl-useraccount{
	display: none;
}
.topof-Header,.mainNav{
	width: 100%;
	float: left;
}
.topof-Header ul{
	float: right;
	padding: 4px 0;
}
.topof-Header ul li{
	float: left;
}
.topof-Header ul li a{
	padding: 0 8px;
	font-size: 13px;
	color:#17183B;
}
.topof-Header ul li:first-child a{
	border-right: 1px solid #ccc;
}
.mainNav{
	background: #005baa;
  /* #0a045b; */
}
.pageContent{
	width: 86%;
	padding: 0 20px;
	margin: 0 auto;
}
/* NAVBAR STYLES */
.navbar-nav .nav-item, .navbar-nav .nav-item a {
  padding: 0 0;
  text-align: center;
  font-size: 14px;
  color: #fff;
  transition: all 0.5s ease;
  position: relative;
}
.navbar-nav .nav-item {
    display:flex;
}
 .navbar-nav .nav-item a {
     align-items:center;
  }
 
.navbar-nav .nav-item:last-child a{
    border-right: 1px solid #8788a1;
}
.navbar-nav .nav-item:hover, .navbar-nav .nav-item.active{
    background: #f3f3f3;
    color: #ff6a00;
}
  .navbar-nav .nav-item:hover a, .navbar-nav .nav-item.active a{
    color: #ff6a00;
  }
.navbar-nav .nav-item {
    height:52px;
  position: relative;
  display: flex !important;
  align-items: center;
  padding: 0;

}
.navbar-nav .nav-item:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  border: 1px solid transparent;
  transform: scaleX(0);
  transition: all ease-in-out .5s;
}
.navbar-nav .nav-item:hover:after,.navbar-nav .nav-item.active:after{
	transform: scaleX(1);  
	width: 100%;
    border-color: #ff341a;
}
.navbar {
	padding: 0 14px;
	width: 90%;
	float: left;
}
.useraccount {width: 10%; float: right; padding: 12px 0;}
.useraccount button{
	padding: 0;
}
.useraccount button:hover{
   color: #3083DC;
}
.useraccount button,.useraccount button:hover,.btn-secondary:not(:disabled):not(.disabled):active,.useraccount button:focus{
	box-shadow: none;
	-webkit-box-shadow: none !important;
   background: none;
   border: none;
   outline: none;
}
.dropdown-menu{
	padding: 0;
}
.dropdown-menu .dropdown-item{
	transition: all 0.5s ease;
	font-size: 13px;
	padding: 5px 14px;
}
.pageContentMain{
	width: 100%;
	clear: both;
}
.promoCards{
	float: left;
	width: 100%;
	padding: 20px 14px;
	margin-bottom: 14px;
	background: #fff;
	box-shadow: 2px 2px 2px #ccc;
}
.nonhcpredirection{
    background: rgba(0,0,0,0.9);
}
.promoCards span{
	display: block;
	text-align: center;
}
.promoCards span .fas,.promoCards span .far{
	font-size: 34px;
	color: #ff6a00;
}
.promoCards span .fas .msl{
	font-size: 34px;
	color: #ff6a00;
}
.promoCards a{
	padding: 10px 0 0 0;
	display: block;
	font-size: 14px;
	text-align: center;
	text-decoration: underline !important;
	color: #3083DC;
}
.promoCards p{
	font-size: 13px;
	text-align: center;
}
.contact-dtls{
	float: left;
	width: 100%;
}
.contact-dtls li{
	clear: both;
	padding: 14px 0;
}
.contact-dtls span{
	width: 24px;
	float: left;
}
.contact-dtls span .fas{
    font-size: 14px;
	padding-right: 10px;
	color: #ff6a00;
}
.contact-dtls small,.contact-dtls a{
	float: right;
	width:calc(100% - 24px);
}
.contact-dtls a{
	padding: 0;
   font-size: 12px;
   text-align: left;
}

.changepasswordContent {
    padding-left: 40px;
    list-style-type: circle;
}

.changepasswordContent li {
    line-height: 28px;
	list-style-type: circle;
}
/* Contact modal design */
.contactUs span {
    float: left;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 100%;
    line-height: 27px;
    display: block;
    color: #ff4500;
    font-size: 13px;
    border: 2px solid #ff4500;
    margin: 0 12px 0 0;
}
.contactUs a{
	line-height: 33px;
}
.verification-content {
    width: 500px;
    margin: 13% auto;
    border: 1px solid #aebecf;
    padding: 20px;
}
/* REGISTER SCREEN STYLES HERE */
.tabsMain .nav-tabs{
	border: none;
}
.tabsMain .nav-tabs{
	width: 650px;
	margin: 0 auto;
}
.tabsMain .nav-tabs .nav-item{
	padding:0 86px;
}
.tabsMain .nav-tabs .nav-item:first-child{
	padding-left: 0;
}
.tabsMain .nav-tabs .nav-item:last-child{
	padding-right: 0;
}

.tabsMain .nav-tabs .nav-item .nav-link{
	padding: 0;
	position: relative;
	height: 24px;
	width: 24px;
	background: #ccc;
	border-radius: 100%;
	transition: all 0.5s ease;
}
.tabsMain .nav-tabs .nav-item .nav-link:after{
	content: '';
	position: absolute;
	left:22px;
	top: 10px;
	width: 175px;
	border-bottom: 3px solid #ccc;
}
.tabsMain .nav-tabs .nav-item:last-child .nav-link:after{
	border: none;
}
.tabsMain .nav-tabs .nav-item.show .nav-link,.tabsMain .nav-tabs .nav-link.active,.tabsMain .nav-tabs .nav-link:hover{
	border: none;
	background: #17183b;
}
.tabsMain .nav-tabs .nav-item .nav-link.active:after,.tabsMain .nav-tabs .nav-link:hover:after{
	border-bottom: 3px solid #17183b;
}
.tabsMain .nav-tabs .nav-item:last-child:hover .nav-link:after,.tabsMain .nav-tabs .nav-item:last-child .nav-link.active:after{
	border: none;
}
.registaerForm-fields{
	max-height: calc(100vh - 160px);
	padding-top: 20px;
	width: 85%;
	margin: 0 auto;
	overflow: auto;
}
/*Upload Button*/
.upload-btn{width: 250px;}
.upload-btn .custom-file-label::after{display: none;}
.custom-file-input{    height: 32px;}
.upload-btn label{    font-size: 14px;
    line-height: 17px;
    height: 32px;
    z-index: 9;
    cursor: pointer;
}
.upload-btn:hover label,.upload-btn label:hover, .upload-btn label:focus{
    background-color: #26a1c7!important;
    border-color: #26a1c7!important;
    color: #fff!important;
    outline: none;
    box-shadow: none;
}
.upload-btn input:focus{outline:none; box-shadow: none;}
.upload-btn .custom-file{    height: 32px;}
.btn-outline-primary:hover .custom-file-label{
    background-color:transparent !important 
}
.btn-outline-primary:hover .upload-icon .custom-file label {
    color: #fff !important;
}
/* Default Tabs */
/* .document-faqTabs{ */
	/*display: none;*/
/* } */
.defaultTabs {
	width: 100%;
	margin: 0 auto;
}
.defaultTabs .nav-tabs .nav-item .nav-link {
    background: #e8e7ff;
    font-size: 14px;
    color: #343434;
    border: none;
    transition: all 0.5s ease;
    border-radius: inherit;
    border-right: 1px solid #cac9dd;
}
.defaultTabs .nav-tabs .nav-link:hover
{
    color: inherit !important;
}
.defaultTabs .nav-tabs .nav-item.show .nav-link, .defaultTabs .nav-tabs .nav-link.active{
	background: #211981;
    color: #fff !important;
}
.documentRow{
	width: 100%;
	float: left;
	padding: 5px 0;
	margin: 5px 0;
}
.documentRow ul li{
	float: left;
	padding-right: 10px;
}
.documentRow ul li a{
    display:flex;
	font-size: 12px;
	line-height: 18px;
}
  .documentRow ul li a span {
    padding-left: 6px;
  }
.documentRow ul li:last-child{
	padding-top: 5px;
	cursor: pointer;
	/*float: right;*/
}
.faqlistRow{
    width:100%;
    float:left;
	padding: 10px 10px 10px 10px;
	border-bottom: 1px solid #ccc;
}
.faqlist{width: 100%;}
.faqlist a{
	position: relative;
	font-size: 13px;
	float: left;
	padding: 0 10px 0 0;
}
.faqlist a strong,.faqlist p strong{
   position: absolute;
   left: -20px;
   width: 18px;
   padding-right: 10px;
}
.faqlist p{
	position: relative;
	clear: both;
   font-size: 14px;
}
.faqlist span
{
    font-size: 14px !important;
    white-space: break-spaces;
    text-overflow: ellipsis !important;
    overflow: hidden;
    /* display: -webkit-box !important;
    -webkit-line-clamp: 5 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: -o-ellipsis-lastline; */
}
.faqlist span.show_text {
    overflow: hidden;
    text-decoration: underline;
    position: absolute;
    bottom: 0;
}

.faqlist span.hide_text {
    overflow: hidden;
    text-decoration: underline;
    display: none;
    position: absolute;
    bottom: 0;
}
.shwng-items {
    font-size: 14px;
    color: #495058;
}
.shwng-items {
    font-size: 14px;
    color: #495058;
}
.pagination-btns .fas {
    font-size: 23px;
    line-height: 1;
    color: #4c6a82;
}
.tabsContainer{
	width: 100%;
	padding: 24px 0;
}
.buttonsRow{
	text-align: right;
}
/* FIND MY MSL */
.msl-detailscard {
    width: 100%;
    background: #fff;
    margin-top: 6px;
    padding: 20px 20px;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
}
.owl-carousel .owl-nav button.owl-prev {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 30px;
    top: -16px;
    font-size: inherit;
    background: url(../images/previw.png) no-repeat left top;
	
}
.owl-carousel .owl-nav button.owl-next {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0;
    top: -16px;
    font-size: inherit;
    background: url(../images/next.png) repeat left top;
}
.owl-carousel .owl-nav button.owl-prev span,.owl-carousel .owl-nav button.owl-next span{font-size: 0;}
#findmyMSL-data{display: none;}
/* EVENTS AND CONFERENCES */
.eventConferenceTabs .nav-tabs .nav-item:first-child .nav-link,.eventConferenceTabs .nav-tabs .nav-item:first-child.show .nav-link,.eventConferenceTabs .nav-tabs .nav-item:first-child .nav-link.active, .eventConferenceTabs .nav-tabs .nav-item:first-child .nav-link:hover{
    background: #ff3b00;
    color: #fff;
}
/* OUR PRODUCTS */
.search-criteria .card .card-header {
    padding: 10px 10px;
    background-color: #ff8303;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
.search-criteria .card-header h2 {
    padding: 10px 0;
    line-height: 15px;
    font-size: 16px;
}
.search-criteria .card-header .fa-minus-square:before, .search-criteria .card-header .fa-plus-square:before {
    color: #fff;
}
.search-criteria .card-header p{
	line-height: 16px;
	color: #fff;
}
.search-criteria-2 .card .card-header {
    padding: 10px 10px;
    background-color: #ff8303c2;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
/* table styles  */
.table {
    display: block;
    overflow: auto;
    border-collapse: separate;
    font-family: 'Roboto', sans-serif;
	font-weight: 400;
    width: 100%;
    border-collapse: separate;
    margin-bottom: 0;
  }
.table-body-wrap {
    /* display: table; */
    width: 100%;
}
.table-body {
    overflow-y: auto;
    overflow-x: visible;
    width: 100%;
}
.table_row {
    display: flex;
transition:all 0.5s ease;
  }
.table_row:hover{
	background: #e2eeff;
} 
.theader {
    display: flex;
    width: 100%;
    background: #3e577c;
  }
.table_row:last-child .table_small{
  border-bottom: 0px;
}
.table_header {
    display: flex;
	padding:6px 10px;
    font-size: 13px;
    vertical-align: middle;
    color: #fff;
	font-weight:500;
  }
.table_header .fas{
		padding-left: 6px;
		font-size: 16px;
}

.table_small {
    /* display: table-cell;
vertical-align:middle; */
display: flex;
align-items: center;
border-bottom:1px solid #a0b2c6;
  }
  
  .table_row > .table_small > .table_cell:nth-child(odd) {
    display: none;
    font-size: 13px;
    vertical-align: middle;
    color: #484b4e;
font-weight:500;
  }

.table_row > .table_small > .table_cell {
   padding: 5px 10px;
    font-size: 13px;
    vertical-align: middle;
    color: #465668;
    width: 100%;
    overflow-wrap: break-word;
  }

  .table_row:last-child > .table_small:last-child > .table_cell:last-child {
    border-bottom-right-radius: 5px;
  }
  
  .table_row:last-child > .table_small:first-child > .table_cell:last-child {
    border-bottom-left-radius: 5px;
  }

  @media screen and (max-width: 900px) {
    .table {
      width: 100%
    }
  }
  
  @media screen and (max-width: 650px) {
    .table {
      display: block;
    }
    .table_row:nth-child(2n+3) {
      background: none;
    }
.table_row:nth-child(2n+3):hover{
background:#d8e8ee;
}
    .theader {
      display: none;
    }
    .table_row > .table_small > .table_cell:nth-child(odd) {
      display: table-cell;
      width: 45%;
 text-align:right;
    }
    .table_cell {
      display: table-cell;
      width: 55%;
 text-align:left;
    }
    .table_row {
      display: table;
      width: 100%;
      border-collapse: separate;
      margin:3% auto 0;
      text-align: center;
 border:1px solid #a0b2c6;
 border-radius:4px;
    }
    .table_small {
      display: table-row;
    }

.table_row > .table_small:last-child > .table_cell{
border-bottom:none;
}
    .table_row > .table_small:first-child > .table_cell:last-child {
      border-left: none;
    }

    .table_row > .table_small:first-child > .table_cell:first-child {
      border-top-left-radius: 5px;
    }
    .table_row > .table_small:first-child > .table_cell:last-child {
      border-top-right-radius: 5px;
    }

    .table_row > .table_small:last-child > .table_cell:first-child {
      border-bottom-left-radius: 5px;
    }
    .table_row > .table_small:last-child > .table_cell:last-child {
      border-bottom-right-radius: 5px;
    }
  }

.table {
    clear: both;
    width: 100%;
    border-collapse: separate;
	margin-bottom:0;
}
.table .tableRow{
border-bottom: solid 1px #a0b2c6;
}
.table .tableRow .tableHead, .table .tableRow .tableCell {
float:left;
    text-align: left;
    padding: 10px 10px;
    font-size: 13px;
    vertical-align: middle;
    color: #484b4e;
}
.table .tableHead-row{
    font-weight: 500;
border-bottom: solid 1px #a0b2c6;
}
.table .tableRow {
width:100%;
display:table;
    padding: 0 0;
    cursor: pointer;
    transition: all 0.5s ease;
}
.table .tableHead-row:hover{
background:none !important;
}
.table .tableRow:hover {
    background: #d8e8ee;
}
.table .tableRow .tableCell {
    color: #465668;
}
.table-drpdwn{display: flex; align-items: center;}
.table-drpdwn .dropdown-toggle::after{display: none;}
.edt-btns{display: inline-block; margin-bottom: 0px; vertical-align: middle;}
.edt-btns li{float: left;
	padding-right: 10px; white-space: nowrap;
}
.grid-4 .table_header:nth-child(-n+4),.grid-4 .table_small:nth-child(-n+4){
	width:20%;
}
.grid-5 .table_header:nth-child(-n+5),.grid-5 .table_small:nth-child(-n+5){
	width:18%;
}
.grid-5 .table_header:last-child,.grid-5 .table_small:last-child{
	width:28%;
}
.myrequestTabs li a{
	padding:6px 10px;
}
/* RESOURCES PAGE */
.row-flex{
	display: flex;
  flex-wrap: wrap;
}
.resourceCard{
	height: 100%;
	position: relative;
	padding: 14px;
	cursor: pointer;
}
.resourceCard:after{
   width: 100%;
   height: 0;
   content: '';
   position: absolute;
   left: 0;
   bottom: 0;
   background: #120989;
   overflow: hidden;
   transition: all 1s ease;
   cursor: pointer;
   border-radius: 4px;
}
.resourceCard a{
    position: absolute;
    left: 0;
    top: 0;
   width: 100%;
   height: 100%;
   /* z-index: 9999; */
}
.resourceCard a i{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.resourceCard:hover:after,.resourceCard:focus:after{
    width: 100%;
	height: 100%;
}
.resourceCard:hover div,.resourceCard:hover p,.resourceCard:hover a{
	color: #fff !important;
}

.owl-carousel .owl-item img
{
    border-radius: 100%;
    width: 60px;
    height: 60px;
}

.msl-description
{
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 32px;
    min-height:32px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* My Request Bookmarks grid*/
.bookmarkcnt-tbl .table_header:nth-child(-n+4),.bookmarkcnt-tbl .table_small:nth-child(-n+4) {
    width: 9%;
}
.bookmarkcnt-tbl .table_header:nth-child(1),.bookmarkcnt-tbl .table_small:nth-child(1) {
	width: 4%;
	text-align: center;
}
.bookmarkcnt-tbl .table_header:nth-child(2),.bookmarkcnt-tbl .table_small:nth-child(2) {
    width: 80%;
}
.viewhead {
    font-size: 14px;
    font-weight: 600;
    color: #616365;
}
.viewdtls {
    font-size: 14px;
    color: #546d8e;
    font-weight: 600;
    line-height: 22px;
    word-break: break-all;
}
/* NEW and Announcements */
.newsCard{
	border-radius: 6px;
}
.newsCard figure{
	position: absolute;
	left: -20px;
	top: 0;
	
}
.newsCard figure img{
   width: 130px;
   margin-top: 6px;
   border-radius: 4px;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.sortField{
	width: 100px;
	float: right;
	border: 0;
	padding-left: 0;
}
.sortField:focus{
   border: 0;
   outline: none;
   box-shadow: inherit;
}
/* Footer Styles */
footer{
	width: 100%;
	padding: 30px 60px 20px 60px;
	clear: both;
	background: #005baa;
  /* #0a045b; */
}
.footerLinks{
	position: relative;
}
.footerLinks:after {
    content: '';
    height: 90px;
    position: absolute;
    right: 30px;
    top: 10px;
    
}
.footerLinks a{
    position: relative;
	padding: 8px 20px;
	font-size: 14px;
	color: #fff;
    display: inline-block;
}
.footerLinks a::after{
    content: '';
    height: 14px;
    position: absolute;
    right: 0;
    top: 12px;
    border: 1px solid #9e98ed;
}
.footerLinks a:last-child:after{
  border: none;
}
.footerLinks a:hover{
	transition: all 0.5s ease;
	color:#3083DC;
}
.scedule-dtls{
	float: right;
}
.scedule-dtls li{
	clear: both;
}
.scedule-dtls li span{
	width: 30px;
	float: left;
}
.scedule-dtls li small{
	color: #fff;
}
.scedule-dtls li a{
	font-size: 13px;
	float: right;
	width: calc(100% - 30px);
	color: #fff;
}
.scedule-dtls a:hover{
	transition: all 0.5s ease;
	color:#3083DC;
}
.scedule-dtls li:nth-child(2){
	padding-top: 14px;
}
.scedule-dtls li:nth-child(1) span{
	padding-top: 12px;
}
.quicklinks{
	width: 100%;
	float: left;
	text-align: center;
	padding: 10px 40px;
}
.quicklinks li{
	display: inline;
}
.quicklinks li a{
	font-size: 14px;
	padding: 0 20px;
	border-right: 1px solid #9fa0ad;
	color: #fff;
}

.quicklinks li:last-child a{
	border: none;
}
.quicklinks li a:hover{
	transition: all 0.5s ease;
	color:#3083DC;
}

.privacyPrompt {
    width: 100%;
    position: relative;
    padding: 15px 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 9999;
    box-shadow: 2px 2px 10px rgb(102, 102, 102);
}  
  .privacyPrompt p {
    padding:0 0 10px 0;
    float: left;
    font-size: 13px;
    color: #6b686c; }
  
  .privacyPrompt a {
    color: #119bb4; }
  
  .privacyPrompt .alertBtn {
    display: inline-block;
    padding: 5px 10px;
    background: #2F54B7;
    border: none;
    color: #fff; }

  .cookieSettings a{
      font-size: 14px;
    padding: 6px 20px;
    margin: 0 3px;
    border: 1px solid #ff6a00;
    background: #ff6a00;
    transition: all 0.5s ease;
    color: #fff;
  }
  .cookieSettings a:hover{
    background: #ff9041;
    color: #fff !important;
  }

::-webkit-scrollbar {
    width: 8px;
    height: 5px
}
    
::-webkit-scrollbar-track {
    background: #fff;
}
    
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 30px;
}

.fs-9 {
    font-size: 9px;
}

.footer-logo img{
    width:150px;
}

@media (max-width: 575px)
{
    .footer-logo{
        width:150px;
        padding-top: 10px;
        margin: 0 auto;
    }
}
@media (max-width: 400px)
{
    .footer-logo{
        width:150px;
        margin: 0 auto;
        padding-top: 10px;
    }
}

/** Scroll and height class to popup
*/

.popup-scroll {
    max-height: calc(100vh - 150px);
    overflow: scroll;
  }
.acc-false {
  padding: 10px 10px;
  background-color: #fff !important;
  color: #6b686c !important;
  border-radius: 10px;
  cursor: pointer;
  border-color: #6b686c !important;
  border: 1px solid !important;
  margin-bottom: 0px;
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.dppr-calendar {
  background: #90caf9 !important;
  padding: 4px 12px !important;
  color: #fff !important;
}

.dppr-cal-brder-0 {
  border-radius:0;
}

.WACLauncher__ButtonContainer .WACLauncher__ButtonContainer--round .WACLauncher__ButtonContainer--noAnimation {
  bottom: 75px;
  right: 10px;
}

.home-img {
  background-color: #ececea;
  border: 1px solid #d7d908;
}