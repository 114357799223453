/* You can add global styles to this file, and also import other style files */
@import url("assets/css/all.css");
@import url("assets/css/animate.css");
@import url("assets/css/bootstrap.min.css");
@import url("assets/css/fontawesome.min.css");
@import url("assets/css/style.css");
@import url("assets/css/responsive.css");
@import url("assets/css/cp.css");
.owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
}

.owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 250px;
}

.owl-prev {
  position: absolute;
  left: 50px;
  top: 5px;
  background: url("assets/images/leftarrow.png")transparent no-repeat left top !important;
  width: 30px;
  height: 30px;
}

.owl-next {
  position: absolute;
  right: 50px;
  top: 5px;
  width: 30px;
  height: 30px;
  background: url("assets/images/rightarrow.png")transparent no-repeat left top !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background: none;
}

.footerLinks a {
  color: #fff !important;
}

  .footerLinks a:hover {
    color: #ff6a00 !important;
  }
.tooltip {
  font-size: 12px !important;
}

/* PrimeNG Calendar CSS Begin*/

.p-calendar {
  height: calc(1.2em + 0.75rem + 0.5px ) !important;
  width: 100% !important;
}

.p-inputtext {
  font-size: 0.8rem !important;
}

.p-datepicker {
  padding: 4px !important;
  z-index: 1060 !important;
  line-height: 1rem !important;
}

  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #2196F3 !important;
    padding: 0.5rem !important;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
  .p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next,
  .p-datepicker .p-datepicker-header {
    color: #fff !important;
  }
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      padding: inherit !important;
    }

    .p-datepicker table th {
      font-size: 13px !important;
      font-weight: inherit !important;
      color: #8daac7 !important;
    }

.p-link {
  font-size: 14px !important;
}

.p-datepicker table td {
  font-size: 0.75rem;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 0rem !important;
}

.p-datepicker .p-datepicker-header {
  padding: 0 6px !important;
}

  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1rem !important;
    /*padding: 0.5rem !important;*/
  }

.p-datepicker table td.p-datepicker-today > span {
  background: #5bc0de !important;
  color: white !important;
}

.p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.p-component, .p-component * {
  box-sizing: border-box;
}

.pi {
  line-height: 0 !important;
  font-size: 0.6rem !important;
}



.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #fff !important;
  background: #68bcff !important;
}
/* PrimeNG Calendar CSS End*/

/* 404 Error Styles */
.error-bg {
  width: 100%;
  height: 232px;
  background: #f5f5f5;
}

  .error-bg h1 {
    text-align: center;
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    -webkit-text-stroke: 2px #ff8a04;
    color: #f5f5f5;
  }

    .error-bg h1 i {
      -webkit-text-stroke: 2px #fe9a27 !important;
      color: #f5f5f5 !important;
    }

    .error-bg h1 i {
      font-size: 70px;
      text-shadow: 1px 1px 2px #ccc;
    }

    .error-bg h1 small {
      font-size: 120px;
      text-shadow: 1px 1px 2px #ccc;
      color: #fff8f8;
    }

    .error-bg h1 span {
      display: block;
      font-size: 18px;
      -webkit-text-stroke: initial !important;
      font-weight: 400;
      color: #7e7c7b !important;
    }
